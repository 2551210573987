import Vue from '/assets/v1/external/vue/vue.js';
import Translator from '/assets/v1/external/gettext-translator/gettext-translator.js';

var version = "v1";
var assetsConfig = {
	version: version
};

var loader = (config) => {
  // Add Translator plugin
  Vue.use(Translator, false);

  // Fetch translations
  let lang = 'en';
  if (document.querySelector('html').lang) {
    lang = document.querySelector('html').lang.substr(0, 2);
  }

  let languageFiles = [];

  config['app'].forEach(function (file) {
    languageFiles.push(`/assets/${assetsConfig.version}/lang/app/${lang}/${file}.json`);
  });
  Object.keys(config['modules']).forEach(function (module) {
    config['modules'][module].forEach(function (file) {
      languageFiles.push(`/assets/${assetsConfig.version}/lang/modules/${lang}/${file}.json`);
    });
  });
  config['external'].forEach(function (file) {
    languageFiles.push(`/assets/${assetsConfig.version}/lang/external/${lang}/${file}.json`);
  });

  let baseUrl = document.querySelector('base').href || '/';

  languageFiles.forEach(function (languageFile) {
    // eslint-disable-next-line no-undef
    fetch(baseUrl + languageFile)
      .then(response => {
        if (response.status === 200) {
          response.json()
            .then(translations => {
              try {
                // Load translations
                Vue.prototype.$i18n.loadTranslations(translations);
              } catch (e) {
                console.warn('Translator: Can\'t load contents of `languageFile` ' + languageFile + ': ' + e);
              }
            })
            .catch((e) => {
              console.warn('Translator: Can\'t parse `languageFile` ' + languageFile + ': ' + e);
            });
        } else {
          throw new Error('Status: ' + response.status)
        }
      })
      .catch((e) => {
        console.warn('Translator: Can\'t fetch `languageFile` ' + languageFile + ': ' + e);
      });
  });

};

loader({
  app: [
    'app-client'
  ],
  modules: {
    core: [
      'core-client'
    ],
    subscription: [
      'subscription-client'
    ]
  },
  external: [
    'vue-datatable',
    'vue-file-manager',
    'vue-treeselect',
    'tiptap',
    'vuelidate-messages'
  ]
});
